const moduleFiles=require.context("./modules",false,/.js$/);
function manyImport() {
    const obj = {};
    moduleFiles.keys().forEach(item => {
        const key = item.replace(/^\.\/(.*)\.js$/,"$1");
        const value = moduleFiles(item).default;
        obj[key] = value
    });
    return obj
}
export default manyImport()
