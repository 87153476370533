<template>
  <div style="padding-top: 40px">
    <div class="bottomCom">
      <div class="mainBody">
        <div>
          <img
            src="../assets/publicImg/logo.svg"
            alt=""
            style="height: 18px; padding: 20px"
          />
        </div>
        <div class="content">
          <div class="content1">
            <div class="contentTitle">关于研视</div>
            <div><span @click="toHome">公司简介</span></div>
            <div>
              <span @click="$router.push('/aboutYells/' + 'history')"
                >研视大事件</span
              >
            </div>
            <div>
              <span @click="$router.push('/aboutYells/' + 'honor')"
                >公司荣誉</span
              >
            </div>
            <div><span @click="$router.push('/recruit')">加入我们</span></div>
          </div>
          <div class="content1">
            <div class="contentTitle">解决方案</div>
            <div><span @click="toAtomMap()">智慧钢铁场景能力地图</span></div>
            <div>
              <span @click="$router.push('/solution/' + 0)">智能值守</span>
            </div>
            <div>
              <span @click="$router.push('/solution/' + 1)">智能检测</span>
            </div>
            <div>
              <span @click="$router.push('/solution/' + 2)">智能安防</span>
            </div>
            <div>
              <span @click="$router.push('/solution/' + 3)">视觉引导</span>
            </div>
          </div>
          <div class="content1">
            <div class="contentTitle">资讯</div>
            <div>
              <span @click="$router.push('/information/' + 1)">研视动态</span>
            </div>
            <div>
              <span @click="$router.push('/information/' + 1)">热点推荐</span>
            </div>
          </div>
          <div class="content2">
            <div class="contentTitle">联系我们</div>
            <div>电话：021-36509952</div>
            <div>手机：18739706251</div>
            <div>lsn@yells.com.cn</div>
            <div>上海市宝山区水产路1269号2号楼5层</div>
            <div class="weixinIcon">
              <img src="../assets/publicImg/weixin.svg" alt="" />
              <p>上海研视信息科技有限公司</p>
            </div>
            <div class="weixinIcon">
              <img src="../assets/publicImg/weixin.svg" alt="" />
              <p>研视AI钢铁</p>
            </div>
            <div class="erweima">
              <img src="../assets/publicImg/erweima1.png" alt="" />
              <img src="../assets/publicImg/erweima2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottomContent">
        <div class="image-text-container">
          <img src="../assets/homePageImg/Plog.png" alt="Description" />
          <div class="text">
            <a
              style="margin-left: 10px; height: 32px; line-height: 32px"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=31011302007887"
              rel="noreferrer"
              target="_blank"
              >沪公网安备31011302007887</a
            >
          </div>
          <div class="text">
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >沪ICP备20017727号-1</a
            >
          </div>
          <div>上海研视信息科技有限公司</div>
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div
        v-show="isShowGoTopButton"
        class="transition-box goHeader"
        @click="goTop"
      >
        <div class="icon"></div>
        <p>回到顶部</p>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "bottomCom",
  data() {
    return {
      isShowGoTopButton: false,
    };
  },
  computed: {
    ...mapState(["METAVERSE_URL"]),
  },
  methods: {
    toAtomMap() {
      window.open(this.METAVERSE_URL, "_blank");
    },
    handleScroll() {
      this.isShowGoTopButton = window.pageYOffset > 200;
    },
    goTop() {
      window.scrollTo({
        // 滚动到顶部
        top: 0,
        behavior: "smooth",
      });
    },
    toHome() {
      window.location.href = "/";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.bottomCom,
.bottom {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.mainBody,
.bottomContent {
  width: 1320px;
  display: flex;
  justify-content: space-between;
}
.content {
  width: 978px;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  .content1,
  .content2 {
    div {
      padding: 4px 14px;
      color: #596773;
      font-family: "Noto Sans SC Variables", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
    }
    .contentTitle {
      padding: 10px;
      color: #2c333a;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .content1 {
    width: 200px;
    span {
      cursor: pointer;
      &:hover {
        color: #b3b9c4;
      }
    }
  }
}
.weixinIcon {
  display: flex;
  p {
    padding-left: 14px;
  }
}
.erweima {
  height: 60px;
  margin-left: 10px;
  img {
    height: 60px;
    margin-right: 10px;
  }
}
.bottomContent {
  height: 56px;
  /*margin-top: 20px;*/
  padding-top: 20px;
  margin: 20px 5px 0;
  border-top: 1px solid #dcdfe4;
  div {
    padding: 10px;
    color: #dcdfe4;
    font-family: "Noto Sans SC Variables", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
}
.goHeader {
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  /*right: 88px;*/
  left: calc(100vw - 124px);
  z-index: 99;
  background-color: #f1f2f4;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: width 0.2s linear;
  /*box-shadow: 0 0 8px 0 rgba(9, 30, 66, 0.1);*/
  .icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 12px;
    height: 12px;
    transition: background 0.2s linear;
    background: url("../assets/publicImg/goTop.svg") center/cover;
  }
  p {
    position: absolute;
    left: 46px;
    width: 56px;
    font-size: 14px;
    line-height: 36px;
    font-weight: 400;
    font-family: "Noto Sans SC Variables", sans-serif;
    color: white;
  }
  &:hover {
    width: 112px;
    background-color: rgba(9, 30, 66, 0.3);
    .icon {
      background: url("../assets/publicImg/goTopHover.svg") center/cover;
    }
  }
}

.image-text-container {
  display: flex;
  align-items: center; /* 垂直居中 */
}

.image-text-container img {
  /* 如果需要，可以在这里添加图片的样式 */
  width: 32px;
  height: 32px;
}

.text {
  padding-left: 4px; /* 添加一些间距 */
}
</style>
