<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
    * {
        margin: 0;
        padding: 0;
    }
    body::-webkit-scrollbar {
        display: none; /* Chrome、Safari等WebKit内核浏览器隐藏滚动条 */
    }
    body {
        scrollbar-width: none; /* Firefox隐藏滚动条 */
    }
    html::-webkit-scrollbar {
        width: 0;
    }
    body::-webkit-scrollbar {
        width: 0;
    }
    /* 隐藏 IE 和 Edge 浏览器的滚动条 */
    ::-ms-scrollbar {
        width: 0;
    }
</style>
